import React, {useEffect} from "react";
import {StyleSheet, View} from "react-native";
import {Image, Text, VStack} from "native-base";
import apps from "./lib/apps";

function Thankyou(){

    useEffect(() => {
        // Replace the current URL
        window.history.replaceState({}, '', '.');
    }, []);

    return (
        <View style={styles.container}>
            <VStack alignItems="center">
                <Image mt={100} h={190} w={190} alt="logo" source={require("../assets/splash_logo.png")}></Image>
                <Text mt={-3} style={styles.version}>VERSION 1.0</Text>

                {/* Thank You Message */}
                <Text style={styles.title}>Thank You!</Text>
                <Text style={styles.subtitle}>
                    Your session has expired. Please scan the table qr code again.
                </Text>

                <Image mt={250} w={150} h={20} resizeMode="contain" alt="medianara" source={require("../assets/medianara.png")}></Image>
            </VStack>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f7f5f2',
        alignItems: 'center',
        justifyContent: 'center',
    },
    version: {
        fontSize: 11,
        color: "#db146d",
        fontWeight: "bold",
        fontFamily: "NotoSans-Black",
    },
    title: {
        fontSize: 28,
        fontWeight: 'bold',
        color: '#333',
        marginTop: 50,
        marginBottom: 10,
    },
    subtitle: {
        fontSize: 16,
        color: '#555',
        textAlign: 'center',
        marginBottom: 30,
        paddingHorizontal: 20,
    },
});

export default Thankyou;

